<template>
  <div>
    <ads
      classification="fundraising"
    >
      <template #ad-select-parent="{ad}">
        <fundraising-project-select
          :filter="{upcoming:true}"
          :fundraising-id.sync="ad.typeModel"
          @update:fundraisingId="ad.type='App\\Models\\FundRaisingProject'"
        />
      </template>
    </ads>
  </div>
</template>
<script>
import Ads from '@/common/components/AppContent/ads/list.vue'
import FundraisingProjectSelect from '@/common/components/Fundraising/FundraisingProjectSelect.vue'

export default {
  name: 'FundraisingAds',
  components: { Ads, FundraisingProjectSelect },
}
</script>
<style lang="">

</style>
